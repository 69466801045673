.section {
    padding-top: 4em;
    padding-bottom: 4em;
    position: relative;
}

.section-dark {
    padding-top: 4em;
    padding-bottom: 4em;
    background-color: #222222;
    position: relative;
}

.header-dark {
    color: white;
}

.header-dark::after {
    content: "";
    display: block;
    width: 1.2em;
    height: 5px;
    background-color: white;
    position: absolute;
    left: 50%;
    margin-left: -0.6em;
}

.btn-outline-dark {
    border: white solid 2px !important;
    background-color: transparent !important;
    color: white !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
}

.btn-outline-dark:hover {
    background-color: white !important;
    color: black !important;
}

.terminalContainer {
    padding-left: 6px;
    padding-right: 6px;
}


/*
    Turn the columns into flex containers
    All this really does is align the links at the bottom,
    is this worth it?
*/
.home-info-col {
    display: flex;
    flex-direction: column;
    align-content: center;
}

.home-info-col svg {
    display: inline-block !important;
    width: 100% !important;
}