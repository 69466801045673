@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
.floatingbtns {
    position: fixed;
    z-index: 5;
}

.floatingbtns.bottomRight {
    bottom: 1rem;
    right: 1rem;
}
.navbar-transparent .navbar-nav .nav-item .nav-link:not(.btn), [class*="bg"] .navbar-nav .nav-item .nav-link:not(.btn) {
    border-color: #000;
}

.navbar .navbar-nav .nav-link {
    line-height: 1.6;
    padding: 10px 15px;
    opacity: .8;
    text-transform: uppercase;
    color: #000!important;
}

.navbar .navbar-nav {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.navbar .navbar-nav .nav-link:hover {
    color: #147a26!important;
}

/* Dropdown */
.dropdown-menu {
    padding: 0!important;
    border: none!important;
}
.dropdown-item {
    color: #000!important;
    padding: 10px 45px 10px 30px!important;
}
.dropdown-item:focus, .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active {
    color: #fff!important;
    text-decoration: none;
    background-color: #147a26!important;
}

@media only screen and (min-width: 992px) {
    .dropdown-menu {
        padding: 0!important;
        box-shadow: 0 2px rgba(17,16,15,.1),0 2px 10px rgba(17,16,15,.1);
        border-radius: 12px!important;
    }

    .dropdown-menu .dropdown-item:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .dropdown-menu .dropdown-item:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .dropdown-item {
        color: #000!important;
        padding: 10px 45px 10px 15px!important;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        color: #fff!important;
        text-decoration: none;
        background-color: #147a26!important;
    }
}

/* Mobile */
.navbar-toggler {
    border: none!important;
    color: #000!important;
}

@media only screen and (max-width: 991px){
    .navbar .navbar-collapse .navbar-nav {
        border-radius: 0% !important;
        border: none !important;
        box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.3);
        transition: box-shadow .2s ease, -webkit-transform .3s cubic-bezier(.34,2,.6,1);
        transition: transform .3s cubic-bezier(.34,2,.6,1), box-shadow .2s ease;
        transition: transform .3s cubic-bezier(.34,2,.6,1), box-shadow .2s ease, -webkit-transform .3s cubic-bezier(.34,2,.6,1);
    }
}

/* Custom styling */
.navbardiv {
    z-index: 5;
    top: 0;
    transition: .25s ease;
}
.navbardiv.bg-white {
    box-shadow: 0 6px 10px -4px rgba(0,0,0,.15);
}
.navbardiv.bg-transparent {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
}

/* Hamburguer */
/* From https://codepen.io/designcouch/pen/Atyop */

.hamburguer {
    width: 35px;
    height: 30px;
    position: relative;
    margin: auto;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
}

.hamburguer span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #147a26;
    border-radius: 4px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.hamburguer span:nth-child(1) {
    top: 0px;
}

.hamburguer span:nth-child(2),.hamburguer span:nth-child(3) {
    top: 12.5px;
}

.hamburguer span:nth-child(4) {
    top: 25px;
}

.hamburguer.open span:nth-child(1) {
    top: 15px;
    width: 0%;
    left: 50%;
}

.hamburguer.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hamburguer.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hamburguer.open span:nth-child(4) {
    top: 15px;
    width: 0%;
    left: 50%;
}
.image-card {
    border-radius: 0% !important;
    border: none !important;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.3);
    transition: transform .3s cubic-bezier(.34,2,.6,1), box-shadow .2s ease,-webkit-transform .3s cubic-bezier(.34,2,.6,1);
}

.image-card:hover {
    -webkit-transform: translateY(-8px) !important;
            transform: translateY(-8px) !important;
    box-shadow: 0 10px 18px 0px rgba(0, 0, 0, 0.3);
}

.image-card * {
    border-radius: 0% !important;
}

.image-card img {
    height: 200px;
    /* object-fit: contain;  not sure which is better, cover is as it is in current site */
    object-fit: cover;
    background-color: white;
}

.image-card .card-body {
    min-height: 198px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

/*
.image-card .card-title {
    font-size: 1em;
} */

.image-card .card-text {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
    display: -webkit-flex;
    display: flex;
}

.image-card .card-text * {
    -webkit-align-self: center;
            align-self: center;
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
}

.image-card .plus-button {
    position: absolute;
    top: 180px;
    left: 8px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 40px;
    height: 40px;
    background-color: white;
    display: -webkit-flex;
    display: flex;
}

.image-card .plus-button span {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -webkit-flex-grow: 1;
            flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 26px;
    font-weight: 100;
}

.image-card a:link, .image-card a:visited, .image-card a:active {
    text-decoration: none;
    color: #147a26;
}

.image-card a:hover {
    text-decoration: none;
    color: #1db939;
}

.image-card.dark a:link, .image-card.dark a:visited, .image-card.dark a:hover, .image-card.dark a:active {
    color: white !important;
}


.image-card.dark, .image-card .dark {
    color: white;
    background-color: #4a4946;
}


.section {
    padding-top: 4em;
    padding-bottom: 4em;
    position: relative;
}

.section-dark {
    padding-top: 4em;
    padding-bottom: 4em;
    background-color: #222222;
    position: relative;
}

.header-dark {
    color: white;
}

.header-dark::after {
    content: "";
    display: block;
    width: 1.2em;
    height: 5px;
    background-color: white;
    position: absolute;
    left: 50%;
    margin-left: -0.6em;
}

.btn-outline-dark {
    border: white solid 2px !important;
    background-color: transparent !important;
    color: white !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
}

.btn-outline-dark:hover {
    background-color: white !important;
    color: black !important;
}

.terminalContainer {
    padding-left: 6px;
    padding-right: 6px;
}


/*
    Turn the columns into flex containers
    All this really does is align the links at the bottom,
    is this worth it?
*/
.home-info-col {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-content: center;
            align-content: center;
}

.home-info-col svg {
    display: inline-block !important;
    width: 100% !important;
}


#linke {
    transition: ease-in-out;
    transition-duration: 0.25s;
    color: rgb(78, 156, 54);
    
}

#linke:hover {
    color: rgb(56, 104, 42);
}

#circle {
    background: rgb(78, 156, 54);
    border-radius: 50%;
    width: 50px;
    position: center;
}

.tabItem {
    transition: ease-in-out;
    transition-duration: 0.0625s;
    margin-bottom: 4px;
}

.tabItem:hover {
    color: rgb(78, 156, 54);
    cursor: pointer;
}

.tab .selected {
    color: rgb(115, 187, 93);
}

.tabDiv {
    border-bottom: rgb(180, 178, 178) 1px solid;
    text-align: "center";
    margin-bottom: 40px;
   
}

.selected ::before {
    
    width: 10px;
    height: 10px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background-color: white;
    content: "";
    display: inline-block;
    position: absolute;
    border-top: rgb(180, 178, 178) 1px solid;
    border-left: rgb(180, 178, 178) 1px solid;
    bottom: -5px;
    left: 45%;
    /*
    border-bottom: 11px solid #fff;
    border-bottom-width: 11px;
    border-bottom-style: solid;
    border-bottom-color: rgb(255, 255, 255);
    border-left: 11px solid transparent;
    border-left-width: 11px;
    border-left-style: solid;
    border-left-color: transparent;
    border-right: 11px solid transparent;
    border-right-width: 11px;
    border-right-style: solid;
    border-right-color: transparent;
    content: "";
    display: inline-block;
    position: absolute;
    right: 40%;
    bottom: 5px;
    box-sizing: border-box;
    */
    
}
.tab-container p {
    transition: ease-in-out;
    transition-duration: 0.0625s;
}

.tab-container p:hover {
    color: rgb(78, 156, 54);
}
.colName {
    font-weight:600;
}
.page-link {
    color: #28a745 !important;
}

.page-item.active .page-link {
    color: white !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
    z-index: 0 !important;
}
b {
    font-weight: bold !important;
}

#article-body a {
    color: #147a26;
}
.document {
    border-radius: .25rem;
    transition: transform .3s cubic-bezier(.34,2,.6,1),box-shadow .2s ease,-webkit-transform .3s cubic-bezier(.34,2,.6,1);
}

.document:hover {
    box-shadow: 0 12px 19px -7px rgba(0,0,0,.3);
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    background-color: white;
    transition: .25s;
}

a.document, a.document:hover {
    text-transform: none;
    text-decoration: none!important;
}
.agendaEvent {
    width:100%;
    height:100%;
    padding: 5px 10px;
}
/* Calendar */
.rbc-calendar {
    box-shadow: 0 6px 10px -4px rgba(0,0,0,.15);
    padding: 15px;
    font-size: inherit!important;
}
.rbc-header {
    color: #999999;
    font-weight: normal;
}

/* Title */
.rbc-toolbar-label {
    -webkit-order: 1;
            order: 1;
    font-size: 1.75rem;
    margin-bottom: 1rem;
    margin-right: auto;
    text-align: left!important;
}

/* Days */
.rbc-btn-group:nth-of-type(1) {
    -webkit-order: 2;
            order: 2;
    margin-right: auto;
    margin-left: auto;
}

.rbc-btn-group:nth-of-type(1)>button:first-of-type {
    margin-left: auto;
}
.rbc-btn-group:nth-of-type(1)>button:last-of-type {
    margin-right: auto;
}

/* Type of view */
.rbc-btn-group:nth-of-type(3) {
    -webkit-order: 3;
            order: 3;
    margin-left:auto;
}
.rbc-btn-group:nth-of-type(3)>button:first-of-type {
    margin-left: auto;
}

/* Buttons */
.rbc-btn-group {
    display: -webkit-flex;
    display: flex;
}
.rbc-btn-group>button,
.rbc-btn-group>button:active,
.rbc-btn-group>button:focus {
    border-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-sizing: border-box;
    border-width: 2px;
    background-color: transparent;
    
    padding: .375rem .75rem;
    border-color: #147a26;
    color: #147a26;
    transition: all .15s linear;
}
.rbc-btn-group>button.rbc-active,
.rbc-btn-group>button:hover {
    color: #fff!important;
    background-color: #147a26 !important;
    border-color: #147a26 !important;
}

.rbc-toolbar-label, .rbc-btn-group {
    margin-bottom: 20px;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
}

/* Views // Month */
.rbc-month-view {
    border: none;
}
.rbc-month-view .rbc-header {
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
}
.rbc-date-cell a {
    color: #000!important;
}
.rbc-off-range a {
    color: #b3b3b3!important;
}
.rbc-off-range-bg {
    background-color: rgba(242, 242, 242, 0.4);
}
.rbc-month-view .rbc-event-content {
    color: #fff!important;
    font-weight: bold;
}

/* Views // Agenda */
.rbc-agenda-table tr {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    background: none!important;
    border: none!important;
}
.rbc-agenda-table tr > td:first-of-type {
    margin-left: auto;
}
.rbc-agenda-table td {
    border-width: 1px 0 0 0!important;
    border-color: #DDD;
    border-style: solid;
    width: 33.33%!important;
    padding-bottom: 2rem!important;
}
.rbc-agenda-table thead th:nth-of-type(1){width:20%!important;max-width:20%!important;}
.rbc-agenda-table thead th:nth-of-type(2){width:30%!important;max-width:30%!important;}
.rbc-agenda-table thead th:nth-of-type(3){width:50%!important;max-width:50%!important;}
.rbc-agenda-table td.rbc-agenda-date-cell{width:20%!important;max-width:20%!important;}
.rbc-agenda-table td.rbc-agenda-time-cell{width:30%!important;max-width:30%!important;}
.rbc-agenda-table td.rbc-agenda-event-cell{width:50%!important;max-width:50%!important;padding:0!important}

.rbc-agenda-table, .rbc-agenda-table thead th {
    border-top:none!important;
    border-left:none!important;
    border-right:none!important;
}
.rbc-agenda-table .rbc-agenda-event-cell {
    color: #fff!important;
    font-weight: bold;
}

/* Responsiveness */
@media only screen and (max-width: 991px) { 
    /* Month header */
    .rbc-toolbar-label {text-align: center!important;}
    .rbc-btn-group:nth-of-type(3)>button:last-of-type{margin-right: auto;}
    .rbc-toolbar-label, .rbc-btn-group {min-width:100%!important;}
    
}
.timeline-item {
    -webkit-justify-content: center;
            justify-content: center;
}

.timeline-card {
    border: 1px solid grey;
    border-radius: 0.30em;
    box-shadow: 0 3px 0 #ddd;
}

.timeline-marker img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.timeline-item:nth-child(2n) {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

.timeline-item:nth-child(2n) .timeline-date {
    text-align: right;
}

/* For smaller screens (992px is bootstrap's LG breakpoint) */
@media only screen and (max-width: 992px) { 
    .timeline-item {
        -webkit-flex-direction: row-reverse;
                flex-direction: row-reverse;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
    }
}


/* bounce-in animation */
@-webkit-keyframes bounce-in {
    to { -webkit-transform: translateX(0); transform: translateX(0); }
}
@keyframes bounce-in {
    to { -webkit-transform: translateX(0); transform: translateX(0); }
}

.bounce-in {
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
    -webkit-animation: bounce-in 0.8 ease forwards;
            animation: bounce-in 0.8 ease forwards;
}
.timeline::before {
    content: "";
    position: absolute;
    height: calc(100% - 210px);
    width: 4px;
    background: #66615b;
    margin-top: -20px;
    margin-left: -2px;
}

/* for large screens only (992px is bootstrap's LG breakpoint) */
@media only screen and (min-width: 992px) {
    .timeline::before {
        left: 50%;
    }
}
.par {
    padding-top: 4em;
    padding-bottom: 4em;
    padding-left: 12em;
    padding-right: 12em;
    background-color: rgb(78, 156, 54);
    position: relative;
    color: white;
}

.par2 {
    padding-top: 4em;
    padding-bottom: 4em;
    background-color: #222222;
    position: relative;
    color: white;
}

.impar {
        padding-top: 4em;
        padding-bottom: 4em;        
        color: black;
        position: relative;

}

.img {
    transition: ease-in-out;
    transition-duration: 0.25s;
}

.img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}


.list-view-header {
    font-weight: bold;
    border-bottom: 2px solid lightgrey;
    padding-top: 6px;
    padding-bottom: 6px;
}

.list-view-item {
    padding: 0;
    border: none !important;
    border-bottom: 1px solid #dfdfdf !important;
    border-radius: 0 !important;
}

.list-view-item .card-header {
    padding: 8px 15px;
    background-color: transparent;
    border-radius: 0;
    margin-left: -15px;
    margin-right: -15px;
    cursor: pointer;
}

.list-view-item .card-body {
    padding-top: 10px;
    padding-bottom: 8px;
}

.list-view-item .card-header:hover {
    background-color: #ebebeb;
}

.list-view-name, .list-view-subject-name, .list-view-subject-short {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.badge {
    color: white;
    margin-left: 6px;
}

.tag-summary {
    background-color: #ffa200;
}
.tag-tests {
    background-color: #147a26;
}
.tag-biblio {
    background-color: #01cae4;
}
.tag-slides {
    background-color: #015a65;
}
.tag-exercises {
    background-color: #20c53e;
}
.tag-projects {
    background-color: #65e67d;
}
.tag-notebook {
    background-color: #d31115;
}

.react-select {
    margin-bottom: 1em;
    
}

.filtros {
    z-index:4
}
.notesDetails {
    border-radius: 0% !important;
    border: none !important;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.3);
    transition: box-shadow .2s ease, -webkit-transform .3s cubic-bezier(.34,2,.6,1);
    transition: transform .3s cubic-bezier(.34,2,.6,1), box-shadow .2s ease;
    transition: transform .3s cubic-bezier(.34,2,.6,1), box-shadow .2s ease, -webkit-transform .3s cubic-bezier(.34,2,.6,1);
}

body {
    font-family: Montserrat, Helvetica,Arial,sans-serif;
    font-weight: 300;
}

/* Bootstrap corrections */
.text-primary { color: #147a26!important }

a { color: #147a26!important }

.tab-container {max-width: none!important;}

.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
    z-index: 0;
}

/* .btn */
.btn {
    font-size: inherit;
    border-width: 2px;
}

.btn-primary {
    color: #fff;
    background-color: #147a26;
    border-color: #147a26;
}
.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus, .btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: #0e581c;
    border-color: #0e581c;
    box-shadow: none;
}

.btn-outline-primary {
    color: #147a26;
    border-color: #147a26;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #147a26;
    border-color: #147a26;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus, .btn-outline-primary.focus, .btn-outline-primary:focus {
    box-shadow: none;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: #147a26;
    border-color: #147a26;
}
.btn-outline-primary-force:hover {
    background-color: #147a26!important;
    color: #fff!important;
}

/* cols from Bootstrap 5 */
.col-xxl-9 {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
}

.pt-10 {
    padding-top: 6rem !important;
}

/* text size */
body { font-size: 1rem!important; }
small, .small, .btn-sm { font-size: .75rem!important; }
h1 { font-size: 3.6rem!important; }
h2 { font-size: 2.8rem!important; }
@media only screen and (max-width: 777px){
    h2 { font-size: 2rem!important; word-break: break-all; }
}
h3 { font-size: 1.75rem!important; }
h4 { font-size: 1.25rem!important; }
h5, .h5 { font-size: 1.15rem!important; }


/* extra Bootstrap */
.link:hover {
    cursor: pointer!important;
}

.mhvh-100 {
    min-height: 100vh;
}

.break-all {
    word-break: break-all;
}

/* removing marker from li with ul inside */
li.sub {
    list-style-type: none;
}

/* animations */
.animation {
    opacity: 0;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    transition: .3s ease all;
}
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes slideUp {
    from {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
    to {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@keyframes slideUp {
    from {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
    to {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.slideUpFade {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
    -webkit-animation-name: slideUp, fadeIn;
            animation-name: slideUp, fadeIn;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
