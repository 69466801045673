.navbar-transparent .navbar-nav .nav-item .nav-link:not(.btn), [class*="bg"] .navbar-nav .nav-item .nav-link:not(.btn) {
    border-color: #000;
}

.navbar .navbar-nav .nav-link {
    line-height: 1.6;
    padding: 10px 15px;
    opacity: .8;
    text-transform: uppercase;
    color: #000!important;
}

.navbar .navbar-nav {
    flex-wrap: wrap;
}

.navbar .navbar-nav .nav-link:hover {
    color: #147a26!important;
}

/* Dropdown */
.dropdown-menu {
    padding: 0!important;
    border: none!important;
}
.dropdown-item {
    color: #000!important;
    padding: 10px 45px 10px 30px!important;
}
.dropdown-item:focus, .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active {
    color: #fff!important;
    text-decoration: none;
    background-color: #147a26!important;
}

@media only screen and (min-width: 992px) {
    .dropdown-menu {
        padding: 0!important;
        box-shadow: 0 2px rgba(17,16,15,.1),0 2px 10px rgba(17,16,15,.1);
        border-radius: 12px!important;
    }

    .dropdown-menu .dropdown-item:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .dropdown-menu .dropdown-item:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .dropdown-item {
        color: #000!important;
        padding: 10px 45px 10px 15px!important;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        color: #fff!important;
        text-decoration: none;
        background-color: #147a26!important;
    }
}

/* Mobile */
.navbar-toggler {
    border: none!important;
    color: #000!important;
}

@media only screen and (max-width: 991px){
    .navbar .navbar-collapse .navbar-nav {
        border-radius: 0% !important;
        border: none !important;
        box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.3);
        transition: transform .3s cubic-bezier(.34,2,.6,1), box-shadow .2s ease;
    }
}

/* Custom styling */
.navbardiv {
    z-index: 5;
    top: 0;
    transition: .25s ease;
}
.navbardiv.bg-white {
    box-shadow: 0 6px 10px -4px rgba(0,0,0,.15);
}
.navbardiv.bg-transparent {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
}

/* Hamburguer */
/* From https://codepen.io/designcouch/pen/Atyop */

.hamburguer {
    width: 35px;
    height: 30px;
    position: relative;
    margin: auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.hamburguer span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #147a26;
    border-radius: 4px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.hamburguer span:nth-child(1) {
    top: 0px;
}

.hamburguer span:nth-child(2),.hamburguer span:nth-child(3) {
    top: 12.5px;
}

.hamburguer span:nth-child(4) {
    top: 25px;
}

.hamburguer.open span:nth-child(1) {
    top: 15px;
    width: 0%;
    left: 50%;
}

.hamburguer.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hamburguer.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hamburguer.open span:nth-child(4) {
    top: 15px;
    width: 0%;
    left: 50%;
}