.par {
    padding-top: 4em;
    padding-bottom: 4em;
    padding-left: 12em;
    padding-right: 12em;
    background-color: rgb(78, 156, 54);
    position: relative;
    color: white;
}

.par2 {
    padding-top: 4em;
    padding-bottom: 4em;
    background-color: #222222;
    position: relative;
    color: white;
}

.impar {
        padding-top: 4em;
        padding-bottom: 4em;        
        color: black;
        position: relative;

}

.img {
    transition: ease-in-out;
    transition-duration: 0.25s;
}

.img:hover {
    transform: scale(1.1);
}

