.document {
    border-radius: .25rem;
    transition: transform .3s cubic-bezier(.34,2,.6,1),box-shadow .2s ease,-webkit-transform .3s cubic-bezier(.34,2,.6,1);
}

.document:hover {
    box-shadow: 0 12px 19px -7px rgba(0,0,0,.3);
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    background-color: white;
    transition: .25s;
}

a.document, a.document:hover {
    text-transform: none;
    text-decoration: none!important;
}