.timeline-item {
    justify-content: center;
}

.timeline-card {
    border: 1px solid grey;
    border-radius: 0.30em;
    box-shadow: 0 3px 0 #ddd;
}

.timeline-marker img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.timeline-item:nth-child(2n) {
    flex-direction: row-reverse;
}

.timeline-item:nth-child(2n) .timeline-date {
    text-align: right;
}

/* For smaller screens (992px is bootstrap's LG breakpoint) */
@media only screen and (max-width: 992px) { 
    .timeline-item {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}


/* bounce-in animation */
@keyframes bounce-in {
    to { transform: translateX(0); }
}

.bounce-in {
    transform: translateX(500px);
    animation: bounce-in 0.8 ease forwards;
}