.badge {
    color: white;
    margin-left: 6px;
}

.tag-summary {
    background-color: #ffa200;
}
.tag-tests {
    background-color: #147a26;
}
.tag-biblio {
    background-color: #01cae4;
}
.tag-slides {
    background-color: #015a65;
}
.tag-exercises {
    background-color: #20c53e;
}
.tag-projects {
    background-color: #65e67d;
}
.tag-notebook {
    background-color: #d31115;
}

.react-select {
    margin-bottom: 1em;
    
}

.filtros {
    z-index:4
}